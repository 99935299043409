"use strict";
Class.register('App.Admin.Login');
App.Admin.Login = (function (base, $) {
    /* Private */
    var _load = function (self) {
        $(window).keydown(function (event) {
            if (event.which === 13) {
                event.preventDefault();

                _signIn(self);
            }
        });

        self.select('.btn-login').click(function (e) {
            e.preventDefault();

            _signIn(self);
        });

        self.select('.username').focus();
    };

    var _signIn = function (self) {
        self.select('.notes').notes('clear');

        var errors = new Array();

        var admin = _getAdmin(self);

        var validator = App.Validator;

        if (validator.isEmpty(admin.username)) {
            errors.push('Emri i perdoruesit duhet vendosur.');
        }

        if (validator.isEmpty(admin.password)) {
            errors.push('Fjalekalimi duhet vendosur.');
        }

        if (errors.length) {
            self.select('.notes').notes('danger', errors);

            $('html,body').scrollTop(0);
        } else {
            App.Api.call({
                url: '/api/admin/signin',
                parameters: admin,
                resultHandler: function (result) {
                    if (!result.SessionId) {
                        if (result.ServiceUnavailable) {
                            errors.push('Sherbimi eshte i padisponueshem.');
                        } else if (result.Suspended) {
                            errors.push('Llogaria juaj eshte çaktivizuar per arsye sigurie.');
                        } else {
                            errors.push('Emri i perdoruesit dhe fjalekalimi jane te gabuar.');
                        }

                        self.select('.notes').notes('danger', errors);

                        $('html,body').scrollTop(0);
                    } else {
                        App.Context.setSessionId(result.SessionId);

                        $('.login-form').submit();
                    }
                }
            });
        }
    };

    var _getAdmin = function (self) {
        return {
            username: self.select('.username').val().trim(),
            password: self.select('.password').val().trim()
        };
    };

    /* Public */
    var self = function (selector) {
        base.call(this, selector);
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);